<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"
        ><div class="ui-type-display-lg">Configurazione</div></CCol
      >
    </CRow>

    <CCard>
      <CCardBody>
        <div class="d-flex justify-content-center" v-if="loading">
          <CSpinner color="info" />
        </div>

        <form action="" @submit.prevent="onSubmit" v-if="invoicing">
          <CRow class="align-items-center mb-2">
            <CCol md="4">
              <strong>Gestisci pagamenti</strong>
              <p>
                Nelle fatture in entrata ed in uscita hai la possibilità di
                indicare il pagamento e veder visualizzati il conteggio dei
                totali
              </p>
            </CCol>
            <CCol md="2">
              <CSwitch
                variant="3d"
                size="sm"
                color="secondary"
                :checked.sync="invoicing.manage_payments"
              />
            </CCol>
          </CRow>
          <CRow class="align-items-center mb-2">
            <CCol md="4">
              <strong>Gestisci incassi</strong>
              <p>
                Nelle fatture emesse hai la possibilità di indicare l'incasso e
                veder visualizzati il conteggio dei totali
              </p>
            </CCol>
            <CCol md="2">
              <CSwitch
                variant="3d"
                size="sm"
                color="secondary"
                :checked.sync="invoicing.manage_income"
              />
            </CCol>
          </CRow>

          <CRow class="align-items-center mb-2">
            <CCol md="4">
              <strong>Emetti fatture automaticamente</strong>
              <p>
                Con l'emissione fatture automatiche, verrà creata una nuova
                fattura ad ogni intervallo definito qui di seguito. Le fatture
                emesse non verranno mai inviate direttamente al cliente dal
                sistema Loonity, ma potrai scaricare il pdf per inviarle in
                autonomia
              </p>
            </CCol>
            <CCol md="2">
              <CSwitch
                variant="3d"
                size="sm"
                color="secondary"
                :checked.sync="invoicing.auto_invoice"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol md="3">
              <CSelect
                label="Intervallo fatturazione"
                :value.sync="invoicing.intervalType"
                :options="intervalTypeOptions"
                :disabled="!invoicing.auto_invoice"
                custom
              >
                <template #label>
                  <label>
                    <strong>Intervallo fatturazione</strong>
                    <em class="text-danger">*</em>
                  </label>
                </template>
              </CSelect>
            </CCol>
            <CCol md="3">
              <CSelect
                v-if="invoicing.intervalType === 1"
                :value.sync="invoicing.intervalSchedule"
                :options="scheduleOptions"
                :disabled="!invoicing.auto_invoice"
                custom
              >
                <template #label>
                  <label>
                    <strong>Intervallo</strong>
                    <em class="text-danger">*</em>
                  </label>
                </template>
              </CSelect>

              <CInput
                v-if="invoicing.intervalType === 2"
                v-model="invoicing.intervalLimitAmount"
                :disabled="!invoicing.auto_invoice"
                :append="currencySymbol"
                type="number"
                step="1"
                min="0"
                custom
              >
                <template #label>
                  <label>
                    <strong>Importo da superare</strong>
                    <em class="text-danger">*</em>
                  </label>
                </template>
              </CInput>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="6">
              <CTextarea
                label="Condizioni di pagamento"
                v-model="invoicing.payment_terms"
                rows="9"
              />
            </CCol>
          </CRow>

          <vue-ladda
            :loading="saving"
            data-style="zoom-in"
            button-class="btn btn-primary mt-3"
            type="submit"
          >
            SALVA CONFIGURAZIONE
          </vue-ladda>
        </form>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import get from "lodash/get";
import { Query } from "@syncfusion/ej2-data";
import { ApiCall, GetDataManager } from "../../../ds";
import { mapGetters } from "vuex";
import {
  AUTO_INVOICE_SCHEDULE,
  AUTO_INVOICE_TYPE,
} from "../../../config/global";
import { parseInvoicingSettings } from "../../../helpers/common";

export default {
  name: "InvoicesSettings",

  data() {
    return {
      loading: false,
      saving: false,
      familyRoleData: null,
      invoicing: null,

      intervalTypeOptions: [
        { value: AUTO_INVOICE_TYPE.DATE, label: "in base alla data" },
        {
          value: AUTO_INVOICE_TYPE.AMOUNT_EXCEEDED,
          label: "Al superamento importo",
        },
      ],

      scheduleOptions: [
        {
          value: AUTO_INVOICE_SCHEDULE.MONTH_BEGINNING,
          label: "primo del mese",
        },
        { value: AUTO_INVOICE_SCHEDULE.MONTH_10, label: "10 del mese" },
        { value: AUTO_INVOICE_SCHEDULE.MONTH_15, label: "15 del mese" },
        { value: AUTO_INVOICE_SCHEDULE.MONTH_END, label: "ultimo del mese" },
      ],
    };
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),
  },

  mounted() {
    this.getFamilyRole();
  },

  methods: {
    getFamilyRole() {
      const self = this;
      this.loading = true;

      const roleId = this.$store.state.role.id;
      const dm = GetDataManager("auth_frole", [], roleId);

      ApiCall(
        dm,
        new Query(),
        (response) => {
          self.loading = false;
          self.familyRoleData = response.result;
          const invoicing = get(self.familyRoleData, "extra.invoicing", null);
          this.invoicing = parseInvoicingSettings(invoicing);
        },
        (response) => {
          self.loading = false;
        }
      );
    },

    prepareInvoicing(data) {
      let invoicing = {
        manage_payments: data.manage_payments,
        manage_income: data.manage_income,
        payment_terms: data.payment_terms,
      };
      if (data.auto_invoice === false) {
        invoicing = {
          ...invoicing,
          auto_invoice: null,
        };
      } else {
        if (data.intervalType === AUTO_INVOICE_TYPE.DATE) {
          invoicing = {
            ...invoicing,
            auto_invoice: {
              based_date: data.intervalSchedule,
            },
          };
        } else if (data.intervalType === AUTO_INVOICE_TYPE.AMOUNT_EXCEEDED) {
          invoicing = {
            ...invoicing,
            auto_invoice: {
              based_amount: parseFloat(data.intervalLimitAmount),
            },
          };
        }
      }
      return invoicing;
    },

    getRoleById(roleId) {
      const dm = GetDataManager("auth_role", [], roleId);
      return dm.executeQuery(new Query()).then((res) => {
        return res.result;
      });
    },

    onSubmit() {
      const roleId = this.$store.state.role.id;
      const requestParams = {
        id: roleId,
        extra: {
          ...this.familyRoleData.extra,
          invoicing: this.prepareInvoicing(this.invoicing),
        },
      };

      this.saving = true;
      GetDataManager("auth_frole")
        .update("", requestParams)
        .then(() => {
          this.saving = false;
          this.getRoleById(roleId).then((data) => {
            this.$store.commit("setRole", data);
          });
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Configurazione aggiornata con successo",
            color: "success",
            autohide: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
          this.$store.dispatch("toaster/add", {
            title: "Attenzione!",
            text: "Si prega di controllare i dati inseriti e riprovare",
            color: "info",
            autohide: true,
          });
        });
    },
  },
};
</script>
